
const EmploymentHistory = ({ formData, setFormData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Employment History</h2>

            <div className="space-y-4 mb-4">
                <div>
                    <label htmlFor="employer0" className="block text-sm font-medium text-gray-700">
                        16. Employer/activity history
                    </label>
                    <p className="text-sm text-gray-600">Please record: employer name and address, your job title and grade, start and end date of employment, brief description of duties and responsibilities</p>
                    <textarea
                        id="employer0"
                        name="employer0"
                        value={formData.employer0 || ''}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="space-y-4 mb-4">
                <div>
                    <label htmlFor="employer1" className="block text-sm font-medium text-gray-700">
                        17. Employer/activity history
                    </label>
                    <p className="text-sm text-gray-600">Please record: employer name and address, your job title and grade, start and end date of employment, brief description of duties and responsibilities</p>
                    <textarea
                        id="employer1"
                        name="employer1"
                        value={formData.employer1 || ''}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="space-y-4 mb-4">
                <div>
                    <label htmlFor="employer2" className="block text-sm font-medium text-gray-700">
                        18. Employer/activity history
                    </label>
                    <p className="text-sm text-gray-600">Please record: employer name and address, your job title and grade, start and end date of employment, brief description of duties and responsibilities</p>
                    <textarea
                        id="employer2"
                        name="employer2"
                        value={formData.employer2 || ''}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="space-y-4 mb-4">
                <div>
                    <label htmlFor="employer3" className="block text-sm font-medium text-gray-700">
                        19. Employer/activity history
                    </label>
                    <p className="text-sm text-gray-600">Please record: employer name and address, your job title and grade, start and end date of employment, brief description of duties and responsibilities</p>
                    <textarea
                        id="employer3"
                        name="employer3"
                        value={formData.employer3 || ''}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                    />
                </div>
            </div>

            <div className="space-y-4 mb-4">
                <div>
                    <label htmlFor="employer4" className="block text-sm font-medium text-gray-700">
                        20. Employer/activity history
                    </label>
                    <p className="text-sm text-gray-600">Please record: employer name and address, your job title and grade, start and end date of employment, brief description of duties and responsibilities</p>
                    <textarea
                        id="employer4"
                        name="employer4"
                        value={formData.employer4 || ''}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                    />
                </div>
            </div>
        </div>
    );
};

export default EmploymentHistory;
