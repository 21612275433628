function TwoCols() {
    return (
        <div>
            <section class="bg-white dark:bg-gray-900">
                <div class="container px-6 py-10 mx-auto">
                    <div class="lg:-mx-6 lg:flex lg:items-center">
                        <img class="object-cover object-center lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg lg:h-[36rem]" src="https://images.unsplash.com/photo-1499470932971-a90681ce8530?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="" />

                        <div class="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">

                            <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl lg:w-96">
                                Our services
                            </h1>
                            <div class="mt-2"><span class="inline-block w-40 h-1 bg-customBlue rounded-full"></span><span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span><span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span></div>
                            <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400 ">
                                Individual candidates: We offer assurance in assisting you from the very first step one of
                                your journey and make the process simple, easy and hassle free, helping you to settle in to
                                any role within the healthcare industry in the uk. We aim not only to help you in a
                                professional capacity but will also provide assistance in your move to the UK offering help
                                and support socially helping you to settle in to a new culture. We at UKMedDirect
                                understand the needs of each and every individual no matter how complex these maybe.
                            </p>

                            <h3 class="mt-6 text-lg font-medium text-customBlue hover:color:text-customBlue"><a href="/about"> Apply now</a></h3>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}



export default TwoCols;