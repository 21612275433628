import React, { useState } from 'react';
import ImmigrationStatus from './Steps/ImmigrationStatus'
import PersonalDetails from './Steps/PersonalDetails';
import ProfessionalDetails from './Steps/ProfessionalDetails';
import EducationalDetails from './Steps/EducationalDetails';
import EmploymentHistory from './Steps/EmploymentHistory';
import SupportingInformation from './Steps/SupportingInformation';

import "./MultiStepForm.css"
import ToastNotification from './Steps/ToastNotification';


function MultiStepForm() {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        address: '',
        gender: '',
        immigration_status: '',
        permit_details: '',
        hcpcNumber: '',
        hcpcExpiryDate: '',
        otherProfessionalRegistration: '',
        qualification0: '',
        placeOfStudy0: '',
        yearObtained0: '',
        result0: '',
        qualification1: '',
        placeOfStudy1: '',
        yearObtained1: '',
        result1: '',
        qualification2: '',
        placeOfStudy2: '',
        yearObtained2: '',
        result2: '',
        qualification3: '',
        placeOfStudy3: '',
        yearObtained3: '',
        result3: '',
        qualification4: '',
        placeOfStudy4: '',
        yearObtained4: '',
        result4: '',
        qualification5: '',
        placeOfStudy5: '',
        yearObtained5: '',
        result5: '',
        relevantTrainingCourses: '',
        employer0: '',
        employer1: '',
        employer2: '',
        employer3: '',
        employer4: '',
        personalStatement: ''
    });

    const nextStep = () => setCurrentStep((prev) => prev + 1);
    const prevStep = () => setCurrentStep((prev) => prev - 1);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(process.env.REACT_APP_PRODUCTION_URL);
        fetch(`${process.env.REACT_APP_PRODUCTION_URL}/api/apply`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          })
          nextStep();
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <ImmigrationStatus formData={formData} setFormData={setFormData} />;
            case 2:
                return <PersonalDetails formData={formData} setFormData={setFormData} />;
            case 3:
              return <ProfessionalDetails formData={formData} setFormData={setFormData} />;
            case 4:
              return <EducationalDetails formData={formData} setFormData={setFormData} />;
            case 5:
              return <EmploymentHistory formData={formData} setFormData={setFormData} />;
            case 6:
              return <SupportingInformation formData={formData} setFormData={setFormData} />;
            case 7:
              return <ToastNotification />;
            
              default:
                return null;
        }
    };


    return (
        <div className="max-w-3xl mx-auto mt-10">
            <form onSubmit={handleSubmit}>
                {renderStep()}
                <div className="flex justify-between mt-4">
                    {currentStep > 1 && (
                        <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded" onClick={prevStep}>
                            Back
                        </button>
                    )}
                    {currentStep < 6 && (
                        <button type="button" className="bg-blue-500 text-white px-4 py-2 rounded" onClick={nextStep}>
                            Next
                        </button>
                    )}
                    {currentStep === 6 && (
                        <button type="submit"  className="bg-green-500 text-white px-4 py-2 rounded">
                            Submit
                        </button>
                    )}
                </div>
            </form>
        </div>

    )
}

export default MultiStepForm