import image from "../homeHero.jpeg";

function Slider() {
  const backgroundStyle = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className="relative">
      <div
        style={backgroundStyle}
        className="h-[800px]  /* Change the height here */
            p-24
            relative
            overflow-hidden
            block
            z-10
            bg-cover
            bg-no-repeat
            bg-center
            before:content-['']
            before:absolute
            before:inset-0 
            before:block
            before:bg-gradient-to-r
            before:from-customBlue-light
            before:to-white
            before:opacity-75
            before:z-[-5]"
      >
        <div className="container m-auto">
          <div className="flex items-center flex-wrap px-2 md:px-0">
            <div className="relative lg:w-6/12 lg:py-24 xl:py-32">
              <h1 className="font-bold text-4xl text-black md:text-5xl lg:w-10/12">
                International Healthcare Recruitment Services.
              </h1>
              <p className="mt-8 text-black-900">
                Are you an international healthcare professional looking to work
                in the UK Healthcare Industry? <br></br>We can assist you in attaining
                and flourishing in your dream role in the UK. <br></br>
                <br></br>
                Are you a healthcare provider needing to recruit Allied Health
                Professionals that are confident, competent and hardworking?
              Look no further, <b>UK Medical Direct</b> can help you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      );
}

export default Slider;
