import React from 'react';

const SupportingInformation = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Supporting Information</h2>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="personalStatement" className="block text-sm font-medium text-gray-700">
            21. Supporting information - Personal Statement
          </label>
          <textarea
            id="personalStatement"
            name="personalStatement"
            value={formData.personalStatement || ''}
            onChange={handleChange}
            rows="6"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            placeholder="Enter your personal statement here..."
          />
        </div>
      </div>
    </div>
  );
};

export default SupportingInformation;
