import { Footer } from "flowbite-react";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import Logo from "./../logo.png";

function CustomFooter() {
  return (
    <div className="bg-gray-50 border-t border-gray-300 py-8">
      <Footer container className="container mx-auto">
        <div className="w-full">
          <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-12 justify-between items-start">
            {/* Logo Section */}
            <div className="flex flex-col items-start mb-6">
              <img
                src={Logo}
                className="h-24 sm:h-24"
                alt="UK Medical Direct Logo"
              />
              <p className="text-sm text-gray-600 mt-4">
                Your trusted partner in medical solutions. Providing excellence
                and care every step of the way.
              </p>
            </div>

            {/* Menu Columns */}
            <div className="grid grid-cols-2 gap-10 sm:grid-cols-3">
              {/* About Section */}
              <div>
                <Footer.Title
                  title="About Us"
                  className="text-lg font-semibold text-gray-800"
                />
                <Footer.LinkGroup col className="mt-2 space-y-2">
                  <Footer.Link
                    href="/About"
                    className="hover:text-customBlue transition-colors"
                  >
                    About
                  </Footer.Link>
                  <Footer.Link
                    href="/Services"
                    className="hover:text-customBlue transition-colors"
                  >
                    Services
                  </Footer.Link>
                  <Footer.Link
                    href="/Contact"
                    className="hover:text-customBlue transition-colors"
                  >
                    Contact
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>

              {/* Follow Us Section */}
              <div>
                <Footer.Title
                  title="Follow us"
                  className="text-lg font-semibold text-gray-800"
                />
                <Footer.LinkGroup col className="mt-2 space-y-2">
                  <Footer.Link
                    href="#"
                    className="hover:text-customBlue transition-colors"
                  >
                    LinkedIn
                  </Footer.Link>
                  <Footer.Link
                    href="#"
                    className="hover:text-customBlue transition-colors"
                  >
                    Twitter
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>

              {/* Legal Section */}
              <div>
                <Footer.Title
                  title="Legal"
                  className="text-lg font-semibold text-gray-800"
                />
                <Footer.LinkGroup col className="mt-2 space-y-2">
                  <Footer.Link
                    href="privacy"
                    className="hover:text-customBlue transition-colors"
                  >
                    Privacy Policy
                  </Footer.Link>
                  <Footer.Link
                    href="terms"
                    className="hover:text-customBlue transition-colors"
                  >
                    Terms &amp; Conditions
                  </Footer.Link>
                  <Footer.Link
                    href="FAQ"
                    className="hover:text-customBlue transition-colors"
                  >
                    FAQs
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>
          </div>

          {/* Divider */}
          <Footer.Divider className="my-8 border-gray-300" />

          {/* Bottom Section */}
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <Footer.Copyright
              href="#"
              by="UK Medical Direct LTD™"
              year={2024}
              className="text-sm text-gray-600"
            />
            <div className="mt-4 sm:mt-0 flex space-x-6 items-center">
              <Footer.Icon
                href="#"
                icon={BsFacebook}
                className="text-gray-600 hover:text-customBlue transition-colors text-lg"
              />
              <Footer.Icon
                href="#"
                icon={BsInstagram}
                className="text-gray-600 hover:text-customBlue transition-colors text-lg"
              />
              <Footer.Icon
                href="#"
                icon={BsTwitter}
                className="text-gray-600 hover:text-customBlue transition-colors text-lg"
              />
              <Footer.Icon
                href="#"
                icon={BsLinkedin}
                className="text-gray-600 hover:text-customBlue transition-colors text-lg"
              />
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
}

export default CustomFooter;
