function ImmigrationStatus({ formData, setFormData }) {
    const handleChange = (event) => {
        setFormData({ ...formData, immigration_status: event.target.value });
      };
    
      const handleTextAreaChange = (event) => {
        setFormData({ ...formData, permit_details: event.target.value });
      };


    return (
        <div class="border p-4">
            <h2 className="text-2xl font-bold mb-4">Immigration Status</h2>
                <p className="font-bold	 mt-2 mb-4">1. Please choose your current immigration status:</p>

                <div className="space-y-4">
                    <div>
                        <input
                            type="radio"
                            id="no_visa"
                            name="immigration_status"
                            value="no_visa"
                            checked={formData.immigration_status === 'no_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="no_visa" className="ml-2">
                            I do not have a visa or right to work in the UK: This applies to individuals WITHOUT the right to Work in the UK. They cannot undertake employment without sponsorship for a visa before applying for entry clearance.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="uk_citizen"
                            name="immigration_status"
                            value="uk_citizen"
                            checked={formData.immigration_status === 'uk_citizen'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="uk_citizen" className="ml-2">
                            I am a UK/EC/EEA citizen with the right to work in the UK
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="indefinite_leave"
                            name="immigration_status"
                            value="indefinite_leave"
                            checked={formData.immigration_status === 'indefinite_leave'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="indefinite_leave" className="ml-2">
                            I have 'Indefinite Leave to Remain in the UK' with no time limit: This applies to individuals who have Indefinite Leave to Remain or Enter with no time limit on their ability to stay in the UK.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="tier1_visa"
                            name="immigration_status"
                            value="tier1_visa"
                            checked={formData.immigration_status === 'tier1_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="tier1_visa" className="ml-2">
                            I have a 'Tier 1 (General) visa' (previously the HSMP - Highly Skilled Migrant Programme): The Tier 1 (General) visas were closed to new entrants on 6 April 2015. Existing Tier 1 visas are still valid until their expiration date, and may still be extended.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="tier2_visa"
                            name="immigration_status"
                            value="tier2_visa"
                            checked={formData.immigration_status === 'tier2_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="tier2_visa" className="ml-2">
                            I have a 'Tier 2 (General) work visa' that was sponsored for me by my current employer: This type of visa has been replaced by the new 'Skilled Worker visa' and 'Health and Care Worker visa' on 1st January 2021. Existing Tier 2 visas are still valid until their expiration date, and may still be extended.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="overseas_nurse"
                            name="immigration_status"
                            value="overseas_nurse"
                            checked={formData.immigration_status === 'overseas_nurse'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="overseas_nurse" className="ml-2">
                            I am an Overseas qualified Nurse or Midwife undertaking Adaptation/ Supervised Practice Placement: The overseas nursing program has been replaced by the new 'Skilled Worker visa' and 'Health and Care Worker visa' on 1st January 2021. Existing visas are still valid until their expiration date, and may still be extended.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="short_term_study"
                            name="immigration_status"
                            value="short_term_study"
                            checked={formData.immigration_status === 'short_term_study'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="short_term_study" className="ml-2">
                            I have a 'Study English in the UK (Short-term study visa)': This type of visa is usually held by an individual who is doing a short course in the UK as part of their overseas study for between 6 - 11 months; no work permitted.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="youth_mobility"
                            name="immigration_status"
                            value="youth_mobility"
                            checked={formData.immigration_status === 'youth_mobility'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="youth_mobility" className="ml-2">
                            I have a 'Youth Mobility Scheme visa (T5)': This type of visa is usually held by someone aged between 18-30 currently living and working in the UK for up to 2 years.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="temporary_worker"
                            name="immigration_status"
                            value="temporary_worker"
                            checked={formData.immigration_status === 'temporary_worker'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="temporary_worker" className="ml-2">
                            I have a 'Temporary Worker - Government Authorised Exchange visa (T5)': This type of visa is usually held by an individual in the UK for a short time, for the purpose of either work experience, training, a Government language programme, research or a fellowship through an approved government authorised exchange scheme.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="dependant_visa"
                            name="immigration_status"
                            value="dependant_visa"
                            checked={formData.immigration_status === 'dependant_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="dependant_visa" className="ml-2">
                            I have a 'Dependant / Spouse visa': These type of visas are usually held by spouses, children or dependant relatives staying with a partner or family member in the UK.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="ancestry_visa"
                            name="immigration_status"
                            value="ancestry_visa"
                            checked={formData.immigration_status === 'ancestry_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="ancestry_visa" className="ml-2">
                            I have a 'UK Ancestry visa': This type of visa is usually held by a Commonwealth citizen whose grandparents were born in the UK.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="refugee"
                            name="immigration_status"
                            value="refugee"
                            checked={formData.immigration_status === 'refugee'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="refugee" className="ml-2">
                            I am a refugee or person with humanitarian protection applying for Indefinite Leave to Remain: This applies to individuals who are applying to settle in the UK (known as 'Indefinite Leave to Remain') with a residence card as either a refuge or person with humanitarian protection.
                        </label>
                    </div>

                    <div>
                        <input
                            type="radio"
                            id="visitor_visa"
                            name="immigration_status"
                            value="visitor_visa"
                            checked={formData.immigration_status === 'visitor_visa'}
                            onChange={handleChange}
                            className="form-radio text-indigo-600"
                        />
                        <label htmlFor="visitor_visa" className="ml-2">
                            I have a 'Standard Visitor visa': This type of visa is usually held by an individual visiting the UK for either holidays, a short course of study, business activities or medical reasons. This cannot include paid or unpaid work and therefore will require sponsorship.
                        </label>
                    </div>
                </div>

                <p className="font-bold mt-4">2. Please supply details of any permit currently held including number, validity and expiry date:</p>
                <textarea
                    id="permit_details"
                    name="permit_details"
                    rows="4"
                    cols="50"
                    value={formData.permit_details}
                    onChange={handleTextAreaChange}
                    className="border border-gray-300 p-2 w-full mt-2"
                />
        </div>

    )
}


export default ImmigrationStatus;