import Slider from '../Components/Slider';
import OneCol from '../Components/OneCol';

function About() {
    return (
        <div>
            <Slider>
            </Slider>
            <OneCol>
                
            </OneCol>
        </div>
    );
}

export default About;