import React from 'react';

import NavBar from './Components/NavBar';
import CustomFooter from './Components/CustomFooter';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import ApplicantApply from './Pages/ApplicantApply';
import RecruiterApply from './Pages/RecruiterApply';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css'
import FAQ from './Pages/FAQ';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';



function ViteTemplatePage() {
  window.location.href = '/dashboard-template/dist'; // Redirect to the static files
  return null;
}

function App() {
  return (

    <BrowserRouter>
      <NavBar></NavBar>
      <Routes>

          <Route path="/">
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsAndConditions />} />
            <Route path="FAQ" element={<FAQ />} />
            <Route path="applicantapply" element={<ApplicantApply/>} />
            <Route path='template/*' element={<ViteTemplatePage />} />
            <Route path="recruiterapply" element={<RecruiterApply />} />
          </Route>
         
      </Routes>

      <CustomFooter></CustomFooter>
    </BrowserRouter>

  );

}

export default App;