import Heading from "../Components/Heading"

function PrivacyPolicy()
{
    return(
        <Heading text="Privacy Policy">
        </Heading>
    )
}

export default PrivacyPolicy;
