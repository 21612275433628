import Slider from '../Components/Slider';
import Features from '../Components/Features';
import TwoCols from '../Components/TwoCols';
import Heading from '../Components/Heading';



function Home() {
    return (
        <div>
            <Slider />
            <Heading text="About Us" subText="We are healthcare professionals with extensive experience and therefore have unique
insights into the needs of both potential candidates and UK healthcare providers. This allows
us to successfully recruit healthcare professionals and put the right people into the right
positions. Through creating long-term relationships with our candidates and healthcare
providers, we offer a very personal and professional tailored approach to every aspect of the
recruitment process, ensuring you find a role that is perfect for you." >
                <h3 class="mt-6 text-lg font-medium text-customBlue hover:color:text-customBlue"><a href="/about"> Read More</a></h3>
            </Heading>
            <TwoCols></TwoCols>
            {/* <Features></Features> */}
        </div>
    );
}

export default Home;