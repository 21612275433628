import Heading from "../Components/Heading"

function TermsAndConditions()
{
    return(
        <Heading text="Terms & Conditions">
        </Heading>
    )
}

export default TermsAndConditions;