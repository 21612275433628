const ProfessionalDetails = ({ formData, setFormData }) => {
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    return (
      <div>
        <h2 className="text-2xl font-bold mb-4">Professional Details</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="hcpcNumber" className="block text-sm font-medium text-gray-700">HCPC Number</label>
            <input
              type="text"
              id="hcpcNumber"
              name="hcpcNumber"
              value={formData.hcpcNumber}
              onChange={handleChange}
              required
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            />
          </div>
          
          <div>
            <label htmlFor="hcpcExpiryDate" className="block text-sm font-medium text-gray-700">Date of Expiry for HCPC</label>
            <input
              type="date"
              id="hcpcExpiryDate"
              name="hcpcExpiryDate"
              value={formData.hcpcExpiryDate}
              onChange={handleChange}
              required
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            />
          </div>
          
          <div>
            <label htmlFor="otherProfessionalRegistration" className="block text-sm font-medium text-gray-700">Other Relevant Professional Registration</label>
            <textarea
              id="otherProfessionalRegistration"
              name="otherProfessionalRegistration"
              value={formData.otherProfessionalRegistration}
              onChange={handleChange}
              rows="4"
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default ProfessionalDetails;