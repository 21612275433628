
const EducationalDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Education and Qualifications</h2>
      
      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification0" className="block text-sm font-medium text-gray-700">
            9. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification0"
            name="qualification0"
            value={formData.qualification0 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy0" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy0"
            name="placeOfStudy0"
            value={formData.placeOfStudy0 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained0" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained0"
            name="yearObtained0"
            value={formData.yearObtained0 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result0" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result0"
            name="result0"
            value={formData.result0 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification1" className="block text-sm font-medium text-gray-700">
            10. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification1"
            name="qualification1"
            value={formData.qualification1 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy1" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy1"
            name="placeOfStudy1"
            value={formData.placeOfStudy1 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained1" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained1"
            name="yearObtained1"
            value={formData.yearObtained1 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result1" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result1"
            name="result1"
            value={formData.result1 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification2" className="block text-sm font-medium text-gray-700">
            11. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification2"
            name="qualification2"
            value={formData.qualification2 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy2" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy2"
            name="placeOfStudy2"
            value={formData.placeOfStudy2 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained2" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained2"
            name="yearObtained2"
            value={formData.yearObtained2 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result2" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result2"
            name="result2"
            value={formData.result2 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification3" className="block text-sm font-medium text-gray-700">
            12. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification3"
            name="qualification3"
            value={formData.qualification3 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy3" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy3"
            name="placeOfStudy3"
            value={formData.placeOfStudy3 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained3" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained3"
            name="yearObtained3"
            value={formData.yearObtained3 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result3" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result3"
            name="result3"
            value={formData.result3 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification4" className="block text-sm font-medium text-gray-700">
            13. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification4"
            name="qualification4"
            value={formData.qualification4 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy4" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy4"
            name="placeOfStudy4"
            value={formData.placeOfStudy4 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained4" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained4"
            name="yearObtained4"
            value={formData.yearObtained4 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result4" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result4"
            name="result4"
            value={formData.result4 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4 mb-4">
        <div>
          <label htmlFor="qualification5" className="block text-sm font-medium text-gray-700">
            14. Subject/Qualification
          </label>
          <input
            type="text"
            id="qualification5"
            name="qualification5"
            value={formData.qualification5 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="placeOfStudy5" className="block text-sm font-medium text-gray-700">
            Place of Study
          </label>
          <input
            type="text"
            id="placeOfStudy5"
            name="placeOfStudy5"
            value={formData.placeOfStudy5 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="yearObtained5" className="block text-sm font-medium text-gray-700">
            Year Obtained
          </label>
          <input
            type="text"
            id="yearObtained5"
            name="yearObtained5"
            value={formData.yearObtained5 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        
        <div>
          <label htmlFor="result5" className="block text-sm font-medium text-gray-700">
            Result/Grade
          </label>
          <input
            type="text"
            id="result5"
            name="result5"
            value={formData.result5 || ''}
            onChange={handleChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label htmlFor="relevantTrainingCourses" className="block text-sm font-medium text-gray-700">
            15. Relevant Training Courses Attended
          </label>
          <textarea
            id="relevantTrainingCourses"
            name="relevantTrainingCourses"
            value={formData.relevantTrainingCourses || ''}
            onChange={handleChange}
            rows="4"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default EducationalDetails;
