
import ContactForm from '../Components/ContactForm';

import Slider from '../Components/Slider';


function Contact() {
    return (
        <div >
            <Slider></Slider>
        <div class="px-20 mt-10 w-full">
            <ContactForm></ContactForm>
        </div>
        
        </div>
    );
}

export default Contact;