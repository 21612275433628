import Slider from '../Components/Slider';

import Service from '../Components/Service';



function Services() {
    return (
        <div>
            <Slider>
            </Slider>

            <div class="px-20 mt-10 w-full" id="services">
                <div class="my-5 heading flex flex-col items-center text-center">
                    <h1 class="text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl lg:w-96">Our Services</h1>
                    <div class="mt-2"><span class="inline-block w-40 h-1 bg-customBlue rounded-full"></span><span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span><span class="text-center inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span></div>
                </div>

                <Service
                    title="Process for Healthcare Professionals"
                    text="text text text"
                    link="/services/service1"
                    direction="ltr" // or "ltr"
                />
                <Service
                    title="Process for Healthcare Providers"
                    text="text text text"
                    link="/services/service1"
                    direction="rtl" // or "ltr"
                />


            </div>
        </div>


    );
}

export default Services;