import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          alert('Message sent successfully!');
          form.current.reset();
        },
        (error) => {
          alert('Failed to send the message. Please try again later.');
          console.error('EmailJS Error:', error);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="space-y-4">
      <label htmlFor="user_name" className="block font-medium">Name</label>
      <input
        type="text"
        name="user_name"
        id="user_name"
        className="w-full rounded-md py-3 px-4 bg-gray-100 text-gray-800 text-sm outline-none focus:ring-2 focus:ring-customBlue"
        required
      />
      
      <label htmlFor="user_email" className="block font-medium">Email</label>
      <input
        type="email"
        name="user_email"
        id="user_email"
        className="w-full rounded-md py-3 px-4 bg-gray-100 text-gray-800 text-sm outline-none focus:ring-2 focus:ring-customBlue"
        required
      />

      <label htmlFor="subject" className="block font-medium">Subject</label>
      <input
        type="text"
        name="subject"
        id="subject"
        className="w-full rounded-md py-3 px-4 bg-gray-100 text-gray-800 text-sm outline-none focus:ring-2 focus:ring-customBlue"
        required
      />
      
      <label htmlFor="message" className="block font-medium">Message</label>
      <textarea
        name="message"
        id="message"
        rows="6"
        className="w-full rounded-md px-4 bg-gray-100 text-gray-800 text-sm pt-3 outline-none focus:ring-2 focus:ring-customBlue"
        required
      ></textarea>
      
      <button
        type="submit"
        className="text-white bg-customBlue hover:bg-customBlue tracking-wide rounded-md text-sm px-4 py-3 w-full mt-6"
      >
        Send
      </button>
    </form>
  );
};

export default ContactForm;
