import Heading from "../Components/Heading"
import RecruiterSignUpForm from "../Components/RecruiterForm"



function RecruiterApply() {
    return (
        <div class="my-8">
            <Heading text="Recruiter Application">
            </Heading>
            <RecruiterSignUpForm />
        </div>
    )
}

export default RecruiterApply;