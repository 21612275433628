import Heading from "../Components/Heading"
import MultiStepForm from "../Components/MultiStepForm"

function ApplicantApply() {
    return (
        <div class="my-8">
            <Heading text="Job Seeker Application">
            </Heading>
            <MultiStepForm />
        </div>
    )
}

export default ApplicantApply;
