function OneCol() {
    return (
        <div>
            <section class="bg-white dark:bg-gray-900">
                <div class="container px-6 py-10 mx-auto">
                    <div class="">

                        <div class="mt-4">
                            <h2 class="h-3 text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">Mission Statement</h2>
                            <div class="">
                                <span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
                                <span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
                            </div>
                            <p>UKMedDirectLimited is a unique professional, diverse and friendly recruitment
                                organisation, with you at the heart of our services. We endeavour to deliver the best
                                possible levels of service for both healthcare organisations and candidates seeking
                                employment opportunities, therefore offering the best in international healthcare
                                recruitment. Our people are the key to achieving this goal. Being Healthcare
                                providers ourselves, we know what it takes to attract and inspire the very best talent
                                in international healthcare recruitment. Our candidates will come with a high degree
                                of good quality training and knowledge about the key elements associated with
                                successful integration in to the UK health sector and culturally integrated with
                                important aspects of living in the United Kingdom.</p>
                            <br></br>
                            <h2 class="h-3 text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">Values</h2>
                            <div class="">
                                <span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
                                <span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
                            </div>
                            <p>UKMedDirect is committed to ensuring ethical recruitment but we can support and
                                facilitate passage to the UK for direct applicants from the WHO list, where
                                candidates have already been appointed or offered employment by an NHS provider.
                                Outstanding candidate support
                                We pride ourselves on offering the best in candidate support. From day one we
                                provide all the guidance needed to prepare for exams, submit visa applications and
                                get ready for your journey. <br></br>
                                On arrival, we offer the warmest of welcome and candidates are given
                                comprehensive support to settle in, well after they&rsquo;ve landed and started their new
                                roles &ndash; from assistance with formal tasks like opening bank accounts and
                                registering with GPs, to helping them make friends and adjust to life in the UK.
                                We also provide all our healthcare workers with the face to face and online training. <br></br> </p>
                                <br></br>
                                <h2 class="h-3 text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">Quality you can trust</h2>
                                <div class="">
                                    <span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
                                    <span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
                                </div>
                                <p>Pre-screening is extremely rigorous with strict compliance checks in line with NHS
                                standards. We focus on high-quality applicants with the very highest levels of
                                experience and language skills.<br></br>
                                We will train our candidates with evidence based and competent training that
                                guarantees every candidates will be up to date in safe practices giving you and them
                                peace of mind allowing</p>
                            <br></br>
                            <h2 class="h-3 text-lg font-semibold text-gray-800 dark:text-white lg:text-lg lg:w-96">Why use us</h2>
                            <div class="">
                                <span class="inline-block w-3 h-1 ml-1 bg-customBlue rounded-full"></span>
                                <span class="inline-block w-1 h-1 ml-1 bg-customBlue rounded-full"></span>
                            </div>
                            <p>Positive outcomes – we ensure candidates are supported from day one to beyond settling into your
                            new job.</p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}



export default OneCol;