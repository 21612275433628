
import FAQTabs from "../Components/FAQTabs"
import Heading from '../Components/Heading';

function FAQ() {


    return (
        <div>
            <Heading text="Frequently Asked Questions" ></Heading>

            <FAQTabs></FAQTabs>
        </div>
    )
}

export default FAQ;