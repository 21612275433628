import React, { useState } from "react";
import "./RecruiterSignUpForm.css"; // Import the CSS file

const RecruiterSignUpForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyAddress: "",
    contactNumber: "",
    email: "",
    password: "",
    comment: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.companyName)
      newErrors.companyName = "Company Name is required.";
    if (!formData.companyAddress)
      newErrors.companyAddress = "Company Address is required.";
    if (!formData.contactNumber)
      newErrors.contactNumber = "Contact Number is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (!formData.comment) newErrors.comment = "Position is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted successfully:", formData);

      // Send data to backend
      const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/api/recruiter/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })
      const data = await response.json();
      if(response.ok){
        alert("Sign-Up Successful!");
        setFormData({
          firstName: "",
          lastName: "",
          companyName: "",
          companyAddress: "",
          contactNumber: "",
          email: "",
          password: "",
          comment: "",
        });
      }else{
        alert(data.message);
      }
    }
  };

  return (
    <div className="recruiter-form-container">
      <h4 className="recruiter-form-title">Please complete the form</h4>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <div className="recruiter-form-field">
          <label htmlFor="firstName" className="recruiter-form-label">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.firstName && (
            <p className="recruiter-form-error">{errors.firstName}</p>
          )}
        </div>

        {/* Last Name */}
        <div className="recruiter-form-field">
          <label htmlFor="lastName" className="recruiter-form-label">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.lastName && (
            <p className="recruiter-form-error">{errors.lastName}</p>
          )}
        </div>

        {/* Company Name */}
        <div className="recruiter-form-field">
          <label htmlFor="companyName" className="recruiter-form-label">
            Company Name
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.companyName && (
            <p className="recruiter-form-error">{errors.companyName}</p>
          )}
        </div>

        {/* Company Address */}
        <div className="recruiter-form-field">
          <label htmlFor="companyAddress" className="recruiter-form-label">
            Company Address
          </label>
          <textarea
            id="companyAddress"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            rows="3"
            className="recruiter-form-textarea"
          />
          {errors.companyAddress && (
            <p className="recruiter-form-error">{errors.companyAddress}</p>
          )}
        </div>

        {/* Contact Number */}
        <div className="recruiter-form-field">
          <label htmlFor="contactNumber" className="recruiter-form-label">
            Contact Telephone Number
          </label>
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.contactNumber && (
            <p className="recruiter-form-error">{errors.contactNumber}</p>
          )}
        </div>

        {/* Email */}
        <div className="recruiter-form-field">
          <label htmlFor="email" className="recruiter-form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.email && (
            <p className="recruiter-form-error">{errors.email}</p>
          )}
        </div>

        {/* Password */}
        <div className="recruiter-form-field">
          <label htmlFor="password" className="recruiter-form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="recruiter-form-input"
          />
          {errors.password && (
            <p className="recruiter-form-error">{errors.password}</p>
          )}
        </div>

        {/* Comment */}
        <div className="recruiter-form-field">
          <label htmlFor="comment" className="recruiter-form-label">
            Comment
          </label>
          <textarea
            id="comment"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            rows="4"
            placeholder="What positions are you currently looking to hire?"
            className="recruiter-form-textarea"
          />
          {errors.comment && (
            <p className="recruiter-form-error">{errors.comment}</p>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" className="recruiter-form-submit-btn">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default RecruiterSignUpForm;
