import React, { useState } from "react";
import "./LoginModal.css";

const BASE_URL = process.env.REACT_APP_PRODUCTION_URL;

const LoginModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async () => {
    const { email, password } = formData;

    try {
      // CHANGE TO API ENDPOINT
      const response = await fetch(`${BASE_URL}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "An error occurred.");
      }
      console.log(result.data);
      if (result.data) {
        closeModal();
        localStorage.setItem('accessToken', result.data);
        localStorage.setItem('refreshToken', result.data.refreshToken);
        window.location.href = "/template";
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true">
      <div className="modal-content animate-fade-in">
        <h2 className="modal-title">Login</h2>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* Email Field */}
        <div className="modal-field">
          <label htmlFor="email" className="modal-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="modal-input"
            required
          />
        </div>

        {/* Password Field */}
        <div className="modal-field">
          <label htmlFor="password" className="modal-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="modal-input"
            required
          />
          <a href="/forgot-password" className="forgot-password-link">
            Forgot Password?
          </a>
        </div>

        {/* Buttons */}
        <div className="modal-buttons">
          <button
            type="button"
            className="modal-btn modal-cancel-btn"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modal-btn modal-login-btn"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
